.rmcw21x > :not([hidden]) ~ :not([hidden]){--tw-space-y-reverse:0;margin-top:calc(1rem * calc(1 - var(--tw-space-y-reverse)));margin-bottom:calc(1rem * var(--tw-space-y-reverse));}
.c302esn{font-weight:700;}
.r1yx0dr > :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(1.5rem * var(--tw-space-x-reverse));margin-left:calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));}
.d1j2lugf{-webkit-flex:1 1 0%;-ms-flex:1 1 0%;flex:1 1 0%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;font-weight:700;text-transform:capitalize;min-width:50px;max-width:50px;color:#9e9e9e;}
.r1aayiga > :not([hidden]) ~ :not([hidden]){--tw-space-y-reverse:0;margin-top:calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));margin-bottom:calc(0.5rem * var(--tw-space-y-reverse));}
.c1qxsjyk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.r6fa3le > :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(1.5rem * var(--tw-space-x-reverse));margin-left:calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));}
.dj3h7tf{-webkit-flex:1 1 0%;-ms-flex:1 1 0%;flex:1 1 0%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-width:50px;max-width:50px;}
.dgk5j9t{width:1.5rem;height:1.5rem;text-align:center;--tw-bg-opacity:1;background-color:rgba(59,130,246,var(--tw-bg-opacity));margin-left:0.25rem;border-radius:9999px;cursor:pointer;}
.eb2nfmd{--tw-text-opacity:1;color:rgba(255,255,255,var(--tw-text-opacity));font-size:1.125rem;line-height:1.75rem;}
.ikdfzhr{border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgba(255,255,255,var(--tw-bg-opacity));text-align:center;outline:2px solid transparent;outline-offset:2px;width:100%;border:1px solid #e2e2e2;}.ikdfzhr:focus{--tw-border-opacity:1;border-color:rgba(96,165,250,var(--tw-border-opacity));}
.c3on9tn{font-weight:700;}
.r1cm0htv > :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(1.5rem * var(--tw-space-x-reverse));margin-left:calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));}
.d1fvmcgs{-webkit-flex:1 1 0%;-ms-flex:1 1 0%;flex:1 1 0%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;font-weight:700;text-transform:capitalize;min-width:50px;max-width:50px;color:#9e9e9e;}
.retbnhk > :not([hidden]) ~ :not([hidden]){--tw-space-y-reverse:0;margin-top:calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));margin-bottom:calc(0.5rem * var(--tw-space-y-reverse));}
.cj2qp7m{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.rj9ilf8 > :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(1.5rem * var(--tw-space-x-reverse));margin-left:calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));}
.d1tiheaf{-webkit-flex:1 1 0%;-ms-flex:1 1 0%;flex:1 1 0%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-width:50px;max-width:50px;}
.ivgagsi{border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgba(255,255,255,var(--tw-bg-opacity));text-align:center;outline:2px solid transparent;outline-offset:2px;width:100%;border:1px solid #e2e2e2;}.ivgagsi:focus{--tw-border-opacity:1;border-color:rgba(96,165,250,var(--tw-border-opacity));}
.cueszsu{margin-top:1rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}
