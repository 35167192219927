

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.secondary-button, .secondary-button:focus {
  border-color: #8CC98E;
  color: #8CC98E;
}
.secondary-button:hover {
  background-color: #ECF7EC;
  border-color: #8CC98E;
  color: #8CC98E;
}

.powerbi-dashboard {
  height: 100%;
  min-height: 100vh;
}
.powerbi-dashboard iframe {
  height: 100%;
  min-height: 100vh;
}