.c15eo25{--tw-text-opacity:1;color:rgba(255,255,255,var(--tw-text-opacity));}
.c5frc9p{--tw-text-opacity:1;color:rgba(255,255,255,var(--tw-text-opacity));}
.c1jtvzsq{--tw-text-opacity:1;color:rgba(255,255,255,var(--tw-text-opacity));}
.c1xha0tq{--tw-text-opacity:1;color:rgba(255,255,255,var(--tw-text-opacity));}
.q1fa24xx{font-size:1.25rem;line-height:1.75rem;}
.c1pc40lu{width:100%;}
.cb9xsz4{width:100%;}
.t9ldl14{white-space:pre-wrap;}
.cdu2tj1 > :not([hidden]) ~ :not([hidden]){--tw-space-y-reverse:0;margin-top:calc(1rem * calc(1 - var(--tw-space-y-reverse)));margin-bottom:calc(1rem * var(--tw-space-y-reverse));}
.qcrwx15{font-size:1.25rem;line-height:1.75rem;--tw-text-opacity:1;color:rgba(24,144,255,var(--tw-text-opacity));margin-right:0.5rem;}
