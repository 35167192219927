.c8ijmo7{text-align:right;}
.r1hpsnci{margin-bottom:2rem;}
.roodszo{margin-bottom:2rem;}
.d19fs8w8{margin:1rem !important;}
.rpb1art{margin-bottom:1.5rem;}
.r13pwwys{margin-bottom:1.5rem;}
.r19mecb5{margin-bottom:1.5rem;}
.rnh3yhe{margin-bottom:1.5rem;}
.rfrlnjj{margin-bottom:1.5rem;}
.r1nxub4t{margin-top:1rem;}
.cvembmr{text-align:right;}
.f1m9bbtz{margin-top:0.5rem;}
