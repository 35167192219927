.d6ff3ec{margin-top:0px;margin-bottom:0px;}
.aglbovk{margin-top:2rem;margin-bottom:2rem;}
.d1xbecwy{margin:1rem !important;}
.s1jifcr1{padding-left:1rem;padding-right:1rem;width:100%;}
.s3jpd14{padding-left:1rem;padding-right:1rem;text-align:center;width:100%;}
.r1bct10q{margin-left:0.5rem !important;margin-right:0.5rem !important;margin-top:1rem;margin-bottom:1rem;}
.c1xcl48t{text-align:left;}
.c11nlmd0{text-align:right;}
.rh27tbs{margin-left:0.5rem !important;margin-right:0.5rem !important;margin-top:1rem;margin-bottom:1rem;}
.cmtvqkx{text-align:left;}
.c1b0i9i3{text-align:right;}
