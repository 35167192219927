.c1tduqhe > :not([hidden]) ~ :not([hidden]){--tw-space-y-reverse:0;margin-top:calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));margin-bottom:calc(1.5rem * var(--tw-space-y-reverse));}
.rn1vbcs > :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(1rem * var(--tw-space-x-reverse));margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)));}
.s1tawdi2{width:100%;}
.s1jah1gs{width:100%;}
.c1gihuew > :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(0.5rem * var(--tw-space-x-reverse));margin-left:calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));}
.cg3zolx > :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(0.5rem * var(--tw-space-x-reverse));margin-left:calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));}
.ws9ejqw{margin-left:0.5rem;font-size:1.125rem;line-height:1.75rem;--tw-text-opacity:1;color:rgba(220,38,38,var(--tw-text-opacity));}
.w1viz3g9{margin-left:0.5rem;font-size:1.125rem;line-height:1.75rem;--tw-text-opacity:1;color:rgba(220,38,38,var(--tw-text-opacity));}
.wi93w5o{margin-left:0.5rem;font-size:1.125rem;line-height:1.75rem;--tw-text-opacity:1;color:rgba(220,38,38,var(--tw-text-opacity));}
.b1nmgdjl{z-index:10;}
.w12d2llv{margin-left:0.5rem;font-size:1.125rem;line-height:1.75rem;--tw-text-opacity:1;color:rgba(220,38,38,var(--tw-text-opacity));}
.w1byo4gs{margin-left:0.5rem;font-size:1.125rem;line-height:1.75rem;--tw-text-opacity:1;color:rgba(220,38,38,var(--tw-text-opacity));}
.rx27gm1 > :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(0.5rem * var(--tw-space-x-reverse));margin-left:calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));}
